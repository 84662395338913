<template>
 <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
         <b-card
           style="max-width: 40rem;"
           class="mb-2"
         >
           <b-card-text>
            <b-form-group id="fieldset-1" label="E-mail" label-for="input-1">
              <b-form-input id="input-1" v-model="email" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Lisans Bitiş Tarihi" label-for="input-1">
              <b-form-datepicker id="example-datepicker" v-model="lisansbitistarihi" placeholder="Lisans Bitiş" class="mb-2"></b-form-datepicker>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Lokasyon Sınırı" label-for="input-1">
              <b-form-input id="input-1" v-model="lokasyonsinir" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Firma Adi" label-for="input-1">
              <b-form-input id="input-1" v-model="firmaadi" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Kullanıcı Sınırı" label-for="input-1">
              <b-form-input id="input-1" v-model="kullanicisiniri" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Bayi" label-for="input-1">
              <b-form-input id="input-1" v-model="bayi"></b-form-input>
            </b-form-group>
           </b-card-text>
           <b-button variant="primary" @click="LisansOlustur">Oluştur</b-button>
         </b-card>
      </div>
    </div>
 </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';
import moment from 'moment';

export default {
 components: {
      Layout,
      PageHeader,

  },
  data() {
      return {
        title: "Müşteri & Lisans Oluştur",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Lisans & Müşteri işlemleri",
            active: true
          }
        ],
        email:null,
        lisansbitistarihi:null,
        lokasyonsinir:null,
        firmaadi:null,
        kullanicisiniri:null,
        bayi:null,
      }
  },
  created(){
  },
  computed:{
    yetkisi()
      {
        return this.$store.getters["auth/rl"];
      },
  },
  methods: {
   LisansOlustur()
   {
     var vm = this;
     try {

      axiosInstance.post("license/eski-lisans/ekle",{
         email:vm.email,
         lisansbitistarihi:moment(vm.lisansbitistarihi).format("YYYY-MM-DD"),
         lokasyonsinir:vm.lokasyonsinir,
         firmaadi:vm.firmaadi,
         kullanicisiniri:vm.kullanicisiniri,
         bayi:vm.bayi,
      }).then((response) => {        

       if(response.data.error==true)
            {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              vm.$bvToast.toast(response.data.message, {
                title: `Bildirim !`,
                variant: "info",
                solid: true
              });
            }

      }).catch((error)=>{
         vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
      })
      
     } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
     }
   },
  },
}
</script>